import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">

<path d="M1070 1518 l0 -318 179 0 c230 0 285 14 369 96 31 30 57 54 58 54 1
0 21 -29 44 -65 l42 -65 74 0 c41 0 74 2 74 4 0 3 -43 66 -96 141 l-96 136
102 141 c62 86 98 144 92 150 -5 5 -40 8 -77 6 l-68 -3 -46 -68 c-46 -68 -46
-68 -61 -45 -27 43 -85 91 -140 117 -52 24 -67 26 -253 31 l-197 5 0 -317z
m395 255 c28 -8 54 -19 59 -24 6 -5 21 -9 35 -9 15 0 32 -4 40 -9 33 -21 -69
-24 -239 -8 -201 20 -270 33 -270 52 0 7 6 15 13 18 28 11 314 -5 362 -20z
m410 -13 c-3 -5 -22 -12 -41 -16 -24 -4 -32 -9 -23 -15 7 -4 17 -5 21 -3 15 9
8 -7 -29 -72 -21 -35 -46 -66 -56 -70 -9 -3 -15 -9 -13 -13 3 -4 -4 -20 -14
-36 -14 -21 -16 -33 -8 -42 8 -10 6 -13 -8 -13 -16 0 -16 -2 -3 -12 8 -7 33
-39 54 -71 22 -32 49 -64 60 -70 19 -10 19 -11 -10 -23 -48 -21 -72 -17 -79
11 -4 14 -20 32 -37 41 -33 17 -34 16 -59 -27 -5 -9 -33 -32 -62 -52 -48 -34
-62 -38 -153 -47 -108 -10 -285 -13 -307 -5 -10 4 -13 29 -12 98 3 142 3 145
19 160 12 13 12 16 -8 21 -21 6 -21 7 -4 20 16 12 16 14 1 20 -11 4 -13 10 -7
19 6 6 10 47 11 90 0 43 2 81 5 83 3 3 26 2 51 -1 42 -7 46 -9 39 -29 -8 -22
-8 -65 2 -261 7 -149 6 -149 127 -142 103 5 120 14 185 97 25 33 53 61 62 61
14 1 14 2 0 6 -11 3 -15 15 -13 46 0 23 2 45 3 49 0 4 36 9 79 11 72 3 70 3
-25 5 -101 2 -103 2 -103 26 0 13 -6 29 -14 35 -8 6 -16 21 -19 31 -4 17 0 20
36 22 67 2 80 -1 107 -27 35 -34 62 -31 98 9 16 18 38 38 48 44 18 11 17 11
-5 12 -19 0 -21 3 -11 14 16 21 127 36 115 16z m-493 -58 c32 -6 45 -18 103
-100 37 -51 64 -99 61 -107 -10 -26 -106 -153 -120 -158 -8 -3 -56 -7 -108 -8
l-93 -2 -3 192 -2 191 62 -1 c35 0 80 -3 100 -7z"/>
<path d="M1315 1608 c-38 -20 -55 -51 -55 -101 0 -40 5 -53 29 -78 25 -24 38
-29 79 -29 59 0 95 24 112 75 24 73 -29 145 -107 145 -21 0 -47 -6 -58 -12z
m106 -49 c17 -17 20 -29 15 -58 -6 -42 -28 -61 -68 -61 -37 0 -68 32 -68 70 0
62 77 93 121 49z"/>
<path d="M1340 1535 c-25 -31 9 -76 46 -60 17 8 16 9 -8 13 -16 2 -28 7 -28
12 0 5 7 7 15 4 8 -4 22 -3 30 0 13 4 14 10 5 26 -13 23 -42 26 -60 5z"/>
<path d="M769 1753 c-20 -23 -25 -407 -6 -443 12 -24 16 -25 132 -28 104 -2
121 0 132 15 10 12 13 74 13 229 0 180 -2 215 -16 228 -23 24 -236 23 -255 -1z
m251 -22 c6 -12 10 -97 10 -206 0 -109 -4 -194 -10 -206 -10 -17 -22 -19 -124
-19 -95 0 -115 3 -120 16 -12 31 -7 409 6 422 8 8 49 12 120 12 96 0 108 -2
118 -19z"/>
<path d="M821 1661 c-14 -25 -14 -256 -1 -282 9 -16 22 -19 74 -19 42 0 67 5
75 14 8 9 11 60 9 157 l-3 144 -72 3 c-63 2 -73 0 -82 -17z m129 -29 c21 -2
22 -4 10 -16 -8 -8 -31 -19 -50 -25 -35 -10 -35 -10 -5 -10 17 1 36 1 43 0 17
-1 15 -40 -3 -47 -13 -6 -14 -8 -2 -18 8 -6 17 -25 20 -42 5 -25 1 -36 -21
-56 -46 -43 -66 -50 -85 -27 -20 23 -24 115 -4 122 7 3 5 6 -5 6 -27 1 -23 46
5 58 19 9 20 12 5 18 -15 6 -15 9 -2 24 14 15 25 17 94 13z"/>
<path d="M2060 1536 l0 -64 38 0 c44 0 62 12 62 43 0 29 -24 49 -50 42 -16 -4
-20 0 -20 19 0 15 -6 24 -15 24 -12 0 -15 -14 -15 -64z m73 -8 c6 -19 -3 -38
-18 -38 -18 0 -28 17 -21 35 7 18 33 20 39 3z"/>
<path d="M1854 1545 c-8 -20 4 -35 26 -35 10 0 22 -4 25 -10 4 -6 -7 -10 -24
-10 -17 0 -31 -5 -31 -11 0 -6 16 -9 37 -7 48 4 57 32 16 50 l-28 12 28 4 c39
5 33 22 -8 22 -22 0 -38 -6 -41 -15z"/>
<path d="M1952 1518 c3 -43 3 -43 46 -46 l42 -3 0 45 c0 33 -4 46 -14 46 -9 0
-16 -12 -18 -32 -2 -21 -8 -33 -18 -33 -10 0 -16 12 -18 33 -5 48 -24 39 -20
-10z"/>
<path d="M1286 1100 c-19 -20 -15 -40 10 -40 9 0 14 11 14 30 0 35 1 34 -24
10z"/>
<path d="M1520 1110 c-8 -5 -30 -10 -47 -10 -28 0 -33 -4 -32 -22 0 -21 1 -21
8 -4 7 18 8 18 14 3 3 -9 11 -17 17 -17 6 0 9 4 6 9 -3 5 1 11 8 14 9 4 17 -1
19 -11 3 -12 5 -11 6 5 0 12 9 27 19 32 9 6 13 10 7 11 -5 0 -17 -5 -25 -10z"/>
<path d="M1343 1102 c-15 -2 -23 -10 -23 -23 0 -24 27 -25 33 -1 4 14 5 14 6
1 1 -11 11 -16 31 -17 23 0 30 4 30 19 0 24 -18 25 -37 2 -14 -17 -14 -17 -9
-1 6 21 -1 25 -31 20z"/>
<path d="M1633 1099 c-15 -5 -21 -14 -17 -24 8 -20 24 -19 24 2 0 15 2 14 15
-3 14 -18 15 -18 12 3 -4 30 -8 32 -34 22z"/>
<path d="M1112 1078 c3 -32 13 -36 22 -11 6 14 9 15 15 4 10 -16 31 -6 31 15
0 8 -10 14 -25 14 -14 0 -25 -6 -25 -12 0 -9 -4 -8 -10 2 -8 11 -9 9 -8 -12z"/>
<path d="M1192 1085 c4 -26 45 -29 59 -4 8 16 6 19 -16 19 -14 0 -25 -6 -25
-12 0 -9 -4 -8 -11 2 -8 12 -10 11 -7 -5z"/>
<path d="M1680 1080 c0 -11 7 -20 15 -20 8 0 15 9 15 20 0 11 -7 20 -15 20 -8
0 -15 -9 -15 -20z"/>
<path d="M1770 1084 c0 -19 21 -29 30 -14 6 10 9 9 13 -1 4 -10 6 -9 6 4 1 9
5 17 10 17 5 0 12 -8 14 -17 4 -14 5 -13 6 4 1 19 -4 22 -39 21 -26 0 -40 -5
-40 -14z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
